
/**********************
  # Generic Elements & Classes
**********************/

* {
  font-family: "Raleway", sans-serif;
}

p {
  line-height: 26px;
}

a:hover,
a:focus {
  color: rgb(0, 155, 160);
}

.section {
  padding-top: 8rem;
}

.sub-section {
  padding: 3rem 0;
}

/* Footer Links */ 
.links a {
  padding-right: 1.5rem;
  color: gray;
}

.links a:hover,
.links a:focus {
  color: #3273dc;
}

/**********************
  # Header
**********************/

.project-header {
  padding-bottom: 9rem;
  margin-bottom: 3rem;
  background: url("./img/beanbag.png") no-repeat center bottom;
  background-size: 90%;
}

.nav-container {
  position: fixed;
  padding: 0;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
  border-bottom: 1px solid rgb(255, 196, 0);
}

.navbar {
  background: white;
}

.navbar-brand {
  letter-spacing: 0.25rem;
}

.navbar a:hover,
.navbar a:focus {
  color: #ab637f;
}

.navbar-menu {
  box-shadow: none;
}

/**********************
  # Main Section
**********************/


.main-container {
  min-height: 100vh;
  padding-bottom: 316px;
  margin-bottom: -316px;
}

@media screen and (max-width: 768px) {
  .links {
    display: flex;
    justify-content: center;
  }
}


/**********************
  # Hero Section
**********************/

#about-me.section {
  padding-right: 0;
  padding-left: 0;
  padding-top: 2.75rem;
}

.hero {
  background: url("img/legos.jpg") center repeat !important;
  min-height: calc(100vh - 140px);
  position: relative;
}

.hero-bg {
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    5deg,
    rgba(216, 25, 0, 0.85),
    rgba(255, 180, 0, 0.9)
  );
  z-index: 1;
  background-size: cover;
}

/* hero img devices */
.hero-img {
  padding: 4rem 0;
}
.hero-img .st0 {
  fill: #ffffff;
}
.hero-img .st6 {
  fill: #ffffff;
}
.hero-img .st1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.hero-img .st2 {
  opacity: 0.25;
}
.hero-img .st3 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.hero-img .st4 {
  fill: #f48500;
}
.hero-img .st5 {
  fill: #f16400;
}

/* swoop */
.hero-body {
  background: url("img/hero-bg.png") center no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.title {
  font-style: italic;
}

h1.page-title {
  font-size: 3rem;
}

h1.title svg {
  position: relative;
  top: 4px;
}

.hero-body p {
  max-width: 500px;
  margin-bottom: 1rem;
}

.hero-body .subtitle {
  color: #363636;
}

.hero-body p,
.hero-body a {
  color: white;
}

.hero-body a {
  text-decoration: underline;
}

.hero-body a:hover,
.hero-body a:focus,
.hero-body a:active {
  color: rgb(252, 252, 185);
}

.hero-body .button-column {
  display: flex;
  justify-content: center;
}

.hero-body .links a {
  text-decoration: none;
}

.hero-body {
  position: relative;
}

.hero-body .button-column {
  position: absolute;
  bottom: -2.79rem;
  width: 100%;
  left: 0px;
}

.hero-body .button {
  margin: 0 auto;
  width: 300px;
  max-width: 300px;
  height: 100px;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  background: linear-gradient(5deg, #ab637f, #da5386);
  border: 4px solid white;
  transition: transform 0.2s;
  display: block;
  padding-top: 2rem;
  z-index: 3;
}

.hero-body .button svg {
  opacity: 0;
  transition: opacity 0.2s;
}

.hero-body .button:hover,
.hero-body .button:focus {
  background: white;
  border-color: white;
  color: rgb(255, 102, 0);
  transform: translateY(-12px);
}

.hero-body .button:hover svg,
.hero-body .button:focus svg {
  opacity: 1;
}

.portrait-col {
  width: 400px;
  max-width: 400px;
  margin-right: 32px;
}

@media screen and (max-width: 1080px) {
  .hero .columns {
    display: block;
    text-align: center;
  }
  .hero p {
    margin-left: auto;
    margin-right: auto;
  }
  .hero img {
    max-width: 500px;
    margin: 0 auto 3rem;
    display: block;
  }
}

/**********************
  # Project Section
**********************/

.product-img-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
.product-img {
  border: 1px solid silver;
  margin: 0 1rem 1rem 0;
}

.sub-section.reverse .main-columns.columns {
  flex-direction: row-reverse;
}

.category-header {
  padding: 1rem 0 0;
}

.time {
  text-transform: uppercase;
  font-size: 1rem;
}

.technologies {
  display: flex;
  padding: 1rem 0;
  justify-content: left;
  flex-wrap: wrap;
}

.technologies li {
  font-weight: bold;
  padding: 0.25rem 1rem;
  margin-right: 0.25rem;
  background: whitesmoke;
  border-radius: 4px;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.screenshots-unavail {
  padding-left: 300px;
  padding-right: 2rem;
  display: block;
  width: 100%;
  height: 400px;
  padding-top: 160px;
}

/**********************
  # Tesimonials Section
**********************/

#testimonials .sub-section {
  background: WhiteSmoke;
  position: relative;
  padding: 3rem;
}

#testimonials .sub-section svg {
  position: absolute;
  top: -1rem;
  left: -1rem;
}

#testimonials p {
  margin-bottom: 2rem;
}

/**********************
  # Footer Section
**********************/

.footer {
  background: WhiteSmoke;
}

.footer .links {
  display: flex;
  justify-content: center;
}

.footer .links a {
  padding: 0.75rem;
}

.portrait-wrapper {
  margin-bottom: 2.75rem;
}
.portrait {
  width: 230px;
  border-radius: 230px;
  margin: 0 auto;
  display: block;
  border: 8px solid white;
}

@media screen and (max-width: 768px) {
  .portrait {
    margin: 0 auto;
    width: 300px;
    display: block;
  }
  .hero h1,
  .hero .subtitle {
    text-align: center;
  }
}
